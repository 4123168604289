import React from "react"
import Tree from "../components/imageComponents/Tree"
import Logo from "..//components/imageComponents/Logo"
import { Link } from "gatsby"

const Footer = ({ children }) => {
  return (
    <div className="flex flex-wrap ml-8 mt-20">
      <div className="flex-grow">
        <div className="max-w-full">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <div>
          <div className="h-full w-full pt-10 px-4">
            <h2 className="title-font font-medium text-gray-900 uppercase tracking-widest text-sm mb-3">
              Products
            </h2>
            <nav className="list-none mb-10">
              <li className="mb-1">
                <Link to="/wine" className="text-gray-600 hover:text-gray-800">
                  🍇 Wine
                </Link>
              </li>
              <li className="mb-1">
                <Link to="/cider" className="text-gray-600 hover:text-gray-800">
                  🍏 Cider
                </Link>
              </li>
            </nav>
          </div>
        </div>
        <div className="lg:w-1/4 md:w-1/2 w-full px-4">
          <h2 className="title-font font-medium text-gray-900 uppercase tracking-widest text-sm mb-3">
            Membership
          </h2>
          <nav className="list-none mb-10">
            <li className="mb-1">
              <Link to="/club" className="text-gray-600 hover:text-gray-800">
                📦 Club Details
              </Link>
            </li>

            <li className="mb-1">
              <a
                href="https://club.archibaldjames.com"
                className="text-gray-600 hover:text-gray-800"
              >
                ✨ Sign Up Now
              </a>
            </li>
            <li className="mb-1">
              <a
                href="https://club.archibaldjames.com/shop"
                className="text-gray-600 hover:text-gray-800"
              >
                🛒 Shop Online
              </a>
            </li>
          </nav>
        </div>
        <div className="lg:w-1/4 md:w-1/2 w-full px-4">
          <h2 className="title-font font-medium text-gray-900 uppercase tracking-widest text-sm mb-3">
            Etc.
          </h2>
          <nav className="list-none mb-10">
            {/* <li className="mb-1">
              <Link
                to="/leavenworth"
                className="text-gray-600 hover:text-gray-800"
              >
                Leavenworth
              </Link>
            </li> */}

            <li className="mb-1">
              <Link
                to="/stockists"
                className=" text-gray-600 hover:text-gray-800"
              >
                Stockists
              </Link>
            </li>
            <li className="mb-1">
              <Link
                to="/articles"
                className="text-gray-600 hover:text-gray-800"
              >
                Articles
              </Link>
            </li>
            <li className="mb-1">
              <Link to="/blog" className="text-gray-600 hover:text-gray-800">
                Blog
              </Link>
            </li>
            <li className="mb-1">
              <Link to="/terms" className="text-gray-600 hover:text-gray-800">
                Terms of Use
              </Link>
            </li>
            <li className="mb-1">
              <Link to="/contact" className="text-gray-600 hover:text-gray-800">
                Contact
              </Link>
            </li>
            <li className="mb-1">
              <Link to="/jobs" className="text-gray-600 hover:text-gray-800">
                Jobs
              </Link>
            </li>
          </nav>
        </div>
        <div className="">
          <div className=" mx-auto py-4 px-5 ">
            <p className="text-gray-500 text-sm text-left ">
              © 2022 Archibald James
            </p>
            <span className="inline-flex mt-5 ">
              <a
                href="https://www.facebook.com/archibald.james.wine.cider"
                className="text-gray-500"
              >
                <svg
                  fill="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                </svg>
              </a>
              {/* <Link
              to="https://twitter.com/wineciderworks"
              className="ml-3 text-gray-500"
            >
              <svg
                fill="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
              </svg>
            </Link> */}

              <Link
                to="https://instagram.com/archibald.james.wine.cider"
                className="ml-3 text-gray-500"
              >
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                  <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                </svg>
              </Link>

              {/* <a href="/" className="ml-3 text-gray-500">
              <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" className="w-5 h-5" viewBox="0 0 24 24">
                <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
                <circle cx="4" cy="4" r="2" stroke="none"></circle>
              </svg>
            </a> */}
            </span>
          </div>
        </div>
      </div>
      <div className="">
        <Tree />
      </div>
    </div>
  )
}

export default Footer
