import React from "react"
import Header from "../Header"

import Footer from "../Footer"

export default function Layout({ children }) {
  return (
    <body className="h-full bg-gray-50">
      <div className="mx-auto px-5 md:px-10  ">
        <Header />
        <main>{children}</main>
      </div>
      <Footer />
    </body>
  )
}
