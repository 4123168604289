import React from "react"
import { Link } from "gatsby"
import Logo from "../components/imageComponents/Logo"

export default function Header({ children }) {
  return (
    <header className="max-w-7xl  mx-auto text-gray-700 body-font">
      <div className="mx-auto flex flex-wrap p-5 ">
        <Link to="/" className="">
          <Logo />
        </Link>

        <nav className=" md:ml-auto flex flex-wrap items-center text-base justify-center">
          <div className="m-1.5 inline-flex rounded-md shadow">
            <Link
              to="/wine"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50"
              activeClassName="border bg-indigo-100"
            >
              Wine
            </Link>
          </div>

          <div className="m-1.5 inline-flex rounded-md shadow">
            <Link
              to="/cider"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50"
              activeClassName="border bg-indigo-100"
            >
              Cider
            </Link>
          </div>

          <div className="m-1.5 inline-flex rounded-md shadow">
            <Link
              to="/taste"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50"
              activeClassName="border bg-indigo-100"
            >
              Taste
            </Link>
          </div>

          <div className="m-1.5 inline-flex rounded-md shadow">
            <Link
              to="/club"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
            >
              Join the club
            </Link>
          </div>
        </nav>
      </div>
    </header>
  )
}
