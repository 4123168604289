import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Tree = () => {
  return (
    <StaticImage
      src="../../images/tree.png"
      alt="Archibald James tree"
      layout="constrained"
      width={400}
      placeholder="tracedSVG"
    />
  )
}

export default Tree
