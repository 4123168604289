import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Logo = () => {
  return (
    <StaticImage
      src="../../images/logo.png"
      alt="Archibald James logo"
      layout="constrained"
      width={375}
      placeholder="tracedSVG"
      className="m-3"
    />
  )
}

export default Logo
